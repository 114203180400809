
import Vue from 'vue'
import { KwikMediumContainer } from 'kwik-vue'
import { dispatchGetCompanyMachines } from '../store/actions'
import { readCompanyMachines } from '../store/getters'
import FFCompanyMachineCard from '../components/FFCompanyMachineCard.vue'

export default Vue.extend({
  name: 'FFCompanyMachine',
  components: { KwikMediumContainer, FFCompanyMachineCard },
  data () {
    return {
      options: {} as any,
      loading: true,
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Parco macchine', disabled: false }
      ]
    }
  },
  computed: {
    companyMachines () {
      return readCompanyMachines(this.$store)
    }
  },
  methods: {
    async refresh () {
      await dispatchGetCompanyMachines(this.$store)
    }
  },
  async mounted () {
    this.refresh()
  }
})
